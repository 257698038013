.profile .profile-photo{
    padding-top: 25px;
}
.css-r11z79-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    overflow: unset !important;
}
.login-form-bx .box-skew1:after{
    content: "";
    background-image: url(../img/body-bg.png) !important;
    height: 100%;
    position: absolute;
    width: 120%;
    left: -30px;
    top: 0;
    background-size: cover;
    z-index: -1;
    transform: skew(-5deg);
}